<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg12 xl12>
        <v-data-table
          dense
          :headers="headers"
          :items="listaNFes"
          class="elevation-10"
          :search="search"
          :footer-props="{
            'items-per-page-options': [5],
          }"
          sortBy="nNF"
          sortDesc
        >
          <template v-slot:top>
            <v-toolbar flat dense>
              <v-icon class="mr-2" color="primary">mdi-account-outline</v-icon>
              <v-toolbar-title>Notas Fiscais</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="blue darken-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metRecarregaLista"
                  >
                    mdi-refresh-circle
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="green darken-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="dialogNFes = true"
                  >
                    mdi-receipt-text-plus
                  </v-icon>
                </template>
                <span>Nova NFe</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  id="tooltip"
                  class="mr-2"
                  color="blue darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                >
                  mdi-lead-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.data_compra`]="{ item }">
            <span>{{ mixDataDBD(item.data_compra) }} </span>
          </template>
        </v-data-table>
        <!-- Dialog NFes -->
        <v-dialog v-model="dialogNFes" max-width="800px">
          <v-card min-width="500" min-height="300">
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="auto" md="2" align-self="center">
                    <v-text-field
                      dense
                      hide-details="auto"
                      v-model="varNFesLocal.id"
                      label="ID"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="auto" md="4" align-self="center">
                    <v-text-field
                      dense
                      hide-details="auto"
                      v-model="varNFesLocal.nNF"
                      label="ID"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="auto" md="4" align-self="center">
                    <v-text-field
                      dense
                      hide-details="auto"
                      v-model="varNFesLocal.CondicaoVenda"
                      label="Condição Venda"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="auto" md="10" align-self="center">
                    <v-text-field
                      dense
                      hide-details="auto"
                      v-model="varNFesLocal.ChaveAcessoNfe"
                      label="Chave de Acesso"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer
              ><v-btn
                dense
                class="mb-4 white--text"
                color="green darken-2"
                @click="save"
              >
                Salvar
              </v-btn>
              <v-btn
                dense
                class="ml-2 mb-4 white--text"
                color="blue darken-1"
                @click="close"
              >
                Cancelar
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Final Dialog NFe -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import DatasMixin from "@/mixins/DatasMixin";
const { mapState, mapActions } = createNamespacedHelpers("puDuesNFes");

export default {
  mixins: [DatasMixin],
  name: "PuDuesNFesLista",
  data: () => ({
    search: "",
    dialogNFes: false,
    headers: [
      { text: "Id", value: "id" },
      {
        text: "DUE",
        align: "start",
        sortable: false,
        value: "idDue",
      },
      {
        text: "Nota",
        align: "start",
        sortable: false,
        value: "ChaveAcessoNfe",
      },
      { text: "Chave", value: "ChaveAcessoNfe", align: "start" },
      { text: "Ações", value: "actions", sortable: false },
    ],
    listaNFes: [],
    editedIndex: -1,
    varNFesLocal: {
      id: null,
      detalhe: "",
      data_compra: "",
      id_cliente: 0,
    },
    defaultItem: {
      id: null,
      detalhe: "",
      data_compra: "",
      id_cliente: 0,
    },
    formapag: undefined,
    clienteLocal: {},
  }),

  computed: {
    ...mapState(["erro", "sta"]),

    // clientesLista() {
    //   return this.staDueNFes;
    // },
    formTitle() {
      return this.editedIndex === -1 ? "Nova NF" : "Edita NF";
    },
  },

  created() {
    this.metRecarregaLista();
  },

  watch: {
    erro() {
      if (this.erro) {
        this.$root.snackbar.show({
          type: "error",
          message: this.erro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
    dialogNFes(val) {
      console.log(val);
      val || this.close();
    },
  },

  mounted() {
    //this.obterClientes();
  },

  methods: {
    ...mapActions(["actListarNFes", "criarClienteCompra", "editarCliente"]),

    // initialize() {
    //   /*       if (this.clientes === null) {
    //     this.clientes = null;
    //   } */
    // },

    login() {
      this.$router.push({ name: "Login" });
    },

    selecionarClienteEdicao(cliente) {
      this.selecionarCliente({ cliente });
      this.$router.push({ name: "LojaClientesComprasForm" });
    },

    paraAtivacao(cliente) {
      this.ativarCliente({
        cliente: cliente,
        licenca: this.$store.state.login.licenca,
      });
    },
    resetarCliente() {
      this.resetarClienteSelecionado();
    },
    metVoltar() {
      this.$router.back();
    },
    metRecarregar() {
      // this.clientesLista();
      // this.$root.snackbar.show({
      //   type: "info",
      //   message: "Lista atualizada!",
      // });
    },
    editItem(item) {
      this.editedIndex = this.listaNFes.indexOf(item);
      this.varNFesLocal = Object.assign({}, item);
      this.varNFesLocal.data_compra = this.mixDataDBDCal(item.data_compra);
      this.dialogNFes = true;
    },
    close() {
      this.dialogNFes = false;
      this.$nextTick(() => {
        this.varNFesLocal = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      if (this.editedIndex > -1) {
        // this.varNFesLocal.data_compra = this.dataForm(
        //   this.varNFesLocal.data_compra
        // );
        await this.editarClienteCompra({
          compra: JSON.parse(JSON.stringify(this.varNFesLocal)),
          licenca: this.$store.state.login.licenca,
        });
        this.metRecarregaLista();
        this.$root.snackbar.show({
          type: "success",
          message: "Compra salva!!",
        });
      } else {
        if (this.varNFesLocal.formapag == "avista") {
          this.clienteLocal.id_cliente =
            this.$store.state.lojaClientes.clienteSelecionado.id_cliente;
          this.clienteLocal.ultima_compra = this.varNFesLocal.data_compra;
          await this.editarCliente({
            cliente: JSON.parse(JSON.stringify(this.clienteLocal)),
            licenca: this.$store.state.login.licenca,
          });
        }
        this.varNFesLocal.id_cliente =
          this.$store.state.lojaClientes.clienteSelecionado.id_cliente;
        await this.criarClienteCompra({
          compra: JSON.parse(JSON.stringify(this.varNFesLocal)),
          licenca: this.$store.state.login.licenca,
        });

        this.metRecarregaLista();
        this.$root.snackbar.show({
          type: "success",
          message: "Compra criada!!!",
        });
      }
      this.close();
    },
    async metRecarregaLista() {
      if (this.$store.state.puDuesNFes.puDuesSelecionado != undefined) {
        const cliente = this.$store.state.puDuesNFes.puDuesSelecionado;
        await this.actListarNFes({
          id_cliente: cliente.id_cliente,
          licenca: this.$store.state.login.licenca,
        });
        this.listaNFes = this.staDueNFes;
      }
      this.$root.snackbar.show({
        type: "info",
        message: "Lista atualizada!!",
      });
    },
  },
};
</script>
