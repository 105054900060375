<!-- eslint-disable prettier/prettier -->
<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs10 sm10 md10 lg10 xl8>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-domain</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <v-tabs v-model="varTabs1">
            <v-tab :key="1" href="#gerais"> Gerais </v-tab>
            <v-tab :key="2" href="#notasfiscais"> Notas Fiscais </v-tab>
            <v-tab :key="3" href="#anexacao"> Anexação </v-tab>
          </v-tabs>
          <v-tabs-items v-model="varTabs1">
            <v-tab-item :key="1" value="gerais">
              <template>
                <v-container fluid>
                  <v-row dense>
                    <v-col class="mb-3" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varDueLocal.id"
                        label="ID"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <span>Informações Básicas</span>
                  <v-divider></v-divider>
                  <v-row dense>
                    <v-col class="mt-3" md="4" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varDueLocal.CPFCNPJdoDeclarente"
                        label="CPF/CNPJ do declarante"
                        outlined
                        v-mask="metMaskCpfCnpj(varDueLocal.CPFCNPJdoDeclarente)"
                        :error-messages="compCPFCNPJdoDeclarenteErrors"
                        @blur="metNomeRazao(varDueLocal.CPFCNPJdoDeclarente)"
                      ></v-text-field>
                    </v-col>
                    <v-col class="mt-3" md="8" align-self="center">
                      <v-chip
                        v-if="varDueLocal.nomeRazaoDeclarante"
                        dense
                        :color="corNomeRazao"
                        :text-color="corNomeRazao"
                        outlined
                        >{{ varDueLocal.nomeRazaoDeclarante }}</v-chip
                      >
                    </v-col>
                  </v-row>
                  <!-- 
              Forma de exportação:    CUS
              Por conta própria                           1001
              Por conta e ordem de terceiros              1002
              Por operador de remessa postal ou expressa  1003
                -->
                  <v-row dense style="padding-top: 0px; margin: 0px">
                    <v-col
                      style="padding-top: 4px; margin: 0px"
                      md="8"
                      align-self="center"
                      dense
                    >
                      <v-radio-group
                        style="padding-top: 0px; margin: 0px"
                        disabled
                        mandatory
                        dense
                        column
                        v-model="varDueLocal.FormadeExportacaoCode"
                      >
                        <span> Forma de exportação: </span>
                        <v-radio
                          style="padding-top: 0px; margin: 0px"
                          label="Por conta própria"
                          value="1001"
                        ></v-radio>
                        <v-radio
                          style="padding-top: 0px; margin: 0px"
                          label="Por conta e ordem de terceiros"
                          value="1002"
                        ></v-radio>
                        <v-radio
                          label="Por operador de remessa postal ou expressa"
                          value="1003"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col md="4" align-self="center">
                      <v-checkbox
                        dense
                        v-model="varDueLocal.ExportacaoConsorciadaTypeCode"
                        label="Exportação Consorciada"
                        value="AGT"
                        disabled
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <!-- 
                Situação especial de despacho:    AHZ 
                DU-E a posteriori                             2001
                Embarque antecipado                           2002
                Exportação sem saída da mercadoria do país    2003
                -->
                  <v-row dense style="padding-top: 0px; margin: 0px">
                    <v-col
                      style="padding-top: 0px; margin: 0px"
                      cols="auto"
                      md="6"
                      align-self="center"
                    >
                      <v-select
                        style="padding-top: 0px; margin: 0px"
                        hide-details="auto"
                        dense
                        disabled
                        label="Situação especial de despacho:"
                        v-model="varDueLocal.SituacaoEspecialdeDespachoCode"
                        :items="[
                          { texto: 'Nenhuma', valor: null },
                          { texto: 'Despacho a posteriori', valor: '2001' },
                          { texto: 'Embarque antecipado', valor: '2002' },
                          {
                            texto: 'Sem saída da mercadoria do país',
                            valor: '2003',
                          },
                        ]"
                        item-text="texto"
                        item-value="valor"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                  <!-- 
              Tipo de documento fiscal que ampara as mercadorias a serem exportadas:    Ao escolher abre campos diferentes sem precisar preencher um campo avisando disso
              Nota Fiscal Eletrônica (NF-e)   - se insere as notas
              Nota Fiscal Formulário          - campos para prencher dados do formulario
              Sem nota fiscal                 - abre um detalhamento e outros campos necessários
                -->
                  <v-row dense style="padding-top: 0px; margin: 0px">
                    <v-col
                      style="padding-top: 0px; margin: 0px"
                      md="10"
                      align-self="center"
                      dense
                    >
                      <v-radio-group
                        style="padding-top: 0px; margin: 0px"
                        mandatory
                        disabled
                        dense
                        column
                        v-model="varDueLocal.FormadeExportacaoCode"
                      >
                        <span
                          >Tipo de documento fiscal que ampara as mercadorias a
                          serem exportadas:
                        </span>
                        <v-radio
                          style="padding-top: 0px; margin: 0px"
                          label="Nota Fiscal Eletrônica (NF-e)"
                          value="1001"
                        ></v-radio>
                        <v-radio
                          style="padding-top: 0px; margin: 0px"
                          label="Nota Fiscal Formulário"
                          value="1002"
                        ></v-radio>
                        <v-radio label="Sem nota fiscal" value="1003"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row dense style="padding-top: 0px; margin: 0px">
                    <v-col
                      style="padding-top: 0px; margin: 0px"
                      cols="auto"
                      md="6"
                      align-self="center"
                    >
                      <v-autocomplete
                        class="mb-3"
                        style="padding-top: 0px; margin: 0px"
                        hide-details="auto"
                        dense
                        label="Moeda de negociação ou de referência:"
                        v-model="varDueLocal.MoedaTypeCode"
                        :items="compMoedasSelect"
                        :item-text="(item) => item.pais + ' - ' + item.moeda"
                        item-value="moeda"
                        outlined
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col style="padding-top: 0px; margin: 0px">
                      <v-text-field
                        dense
                        hide-details="auto"
                        label="Referência única de carga (RUC):"
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <span style="padding: 2px; margin: 4px"
                    >Local de Despacho</span
                  >
                  <v-divider></v-divider>
                  <v-row>
                    <v-col
                      style="padding-top: 0px; margin: 0px"
                      cols="auto"
                      md="8"
                      align-self="center"
                    >
                      <v-autocomplete
                        class="mt-6"
                        style="padding-top: 0px; margin: 0px"
                        hide-details="auto"
                        dense
                        label="Unidade da RFB:"
                        v-model="varDueLocal.UnidadeLocaldeDespacho"
                        :items="compUnidadeLocalRFBSelect"
                        :item-text="
                          (item) => item.codigo + ' - ' + item.descricao
                        "
                        item-value="codigo"
                        @change="(event) => metRecAduanDespSelect(event)"
                        outlined
                        clearable
                      ></v-autocomplete> </v-col
                  ></v-row>

                  <v-row dense style="padding-top: 0px; margin: 0px">
                    <v-col
                      style="padding-top: 0px; margin: 0px"
                      md="3"
                      align-self="center"
                      dense
                    >
                      <!-- Indicador de recinto alfandegado -->
                      <!-- 281 - em recinto alfandegado -->
                      <!-- 22  - fora de recinto alfandegado Não Domiciliar -->
                      <!-- 19 - Fora de Recinto Alfandegado Domiciliar -->
                      <span class="grey--text ml-2"
                        >Em recinto aduaneiro:
                      </span>
                      <v-radio-group
                        style="padding-top: 0px; margin: 0px"
                        class="ml-3"
                        mandatory
                        dense
                        disabled
                        row
                        v-model="
                          varDueLocal.IndicadordeRecintoAlfandegadoDespacho
                        "
                      >
                        <v-radio
                          style="padding-top: 0px; margin: 0px"
                          label="Sim"
                          value="281"
                        ></v-radio>
                        <v-radio
                          class="ml-6"
                          style="padding-top: 0px; margin: 0px"
                          label="Não"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col
                      style="padding-top: 0px; margin: 0px"
                      cols="auto"
                      md="8"
                      align-self="center"
                    >
                      <v-autocomplete
                        class="mt-6 mb-6"
                        style="padding-top: 0px; margin: 0px"
                        hide-details="auto"
                        dense
                        label="Recinto Aduaneiro:"
                        v-model="varDueLocal.RecintoAduaneirodeDespacho"
                        :items="compRecAduanDespSelect"
                        :item-text="
                          (item) => item.codigo + ' - ' + item.descricao
                        "
                        item-value="codigo"
                        outlined
                        clearable
                      ></v-autocomplete>
                    </v-col>
                  </v-row>

                  <span style="padding: 4px; margin: 8px"
                    >Local de Embarque / Transposição de Fronteira</span
                  >
                  <v-divider></v-divider>
                  <v-row>
                    <v-col
                      style="padding-top: 0px; margin: 0px"
                      cols="auto"
                      md="8"
                      align-self="center"
                    >
                      <v-autocomplete
                        class="mt-6 ml-2"
                        style="padding-top: 0px; margin: 0px"
                        hide-details="auto"
                        dense
                        label="Unidade da RFB:"
                        v-model="varDueLocal.UnidadeLocaldeEmbarque"
                        :items="compUnidadeLocalRFBSelect"
                        :item-text="
                          (item) => item.codigo + ' - ' + item.descricao
                        "
                        item-value="codigo"
                        @change="(event) => metRecAduanEmbSelect(event)"
                        outlined
                        clearable
                      ></v-autocomplete> </v-col
                  ></v-row>
                  <v-row dense style="padding-top: 0px; margin: 0px">
                    <v-col
                      style="padding-top: 0px; margin: 0px"
                      md="3"
                      align-self="center"
                      dense
                    >
                      <!-- Indicador de recinto alfandegado -->
                      <!-- 281 - em recinto alfandegado -->
                      <!-- 22  - fora de recinto alfandegado Não Domiciliar -->
                      <!-- 19 - Fora de Recinto Alfandegado Domiciliar -->
                      <span class="grey--text ml-2"
                        >Em recinto aduaneiro:
                      </span>
                      <v-radio-group
                        style="padding-top: 0px; margin: 0px"
                        class="ml-3"
                        mandatory
                        dense
                        disabled
                        row
                        v-model="
                          varDueLocal.IndicadordeRecintoAlfandegadoEmbarque
                        "
                      >
                        <v-radio
                          style="padding-top: 0px; margin: 0px"
                          label="Sim"
                          value="281"
                        ></v-radio>
                        <v-radio
                          class="ml-6"
                          style="padding-top: 0px; margin: 0px"
                          label="Não"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col
                      style="padding-top: 0px; margin: 0px"
                      cols="auto"
                      md="8"
                      align-self="center"
                    >
                      <v-autocomplete
                        class="mt-6 mb-6"
                        style="padding-top: 0px; margin: 0px"
                        hide-details="auto"
                        dense
                        label="Recinto Aduaneiro:"
                        v-model="varDueLocal.RecintoAduaneirodeEmbarque"
                        :items="compRecAduanEmbSelect"
                        :item-text="
                          (item) => item.codigo + ' - ' + item.descricao
                        "
                        item-value="codigo"
                        outlined
                        clearable
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <span style="padding: 4px; margin: 8px">Complementos</span>
                  <v-divider></v-divider>
                </v-container>
                <!-- 
                Via especial de transporte:       TRA 
                Meios próprios                    4001
                Dutos                             4002
                Linhas de transmissão             4003
                Em mãos                           4004
                Por reboque                       4005
                Transporte vicinal fronteiriço    4006
                -->
                <v-row dense style="padding-top: 0px; margin: 0px">
                  <v-col
                    style="padding-top: 0px; margin: 0px"
                    cols="auto"
                    md="6"
                    align-self="center"
                  >
                    <v-select
                      style="padding-top: 0px; margin: 0px"
                      class="ml-4"
                      hide-details="auto"
                      mandatory
                      dense
                      clearable
                      disabled
                      label="Via especial de transporte:"
                      v-model="varDueLocal.ViaEspecialdeTransporteCode"
                      :items="[
                        { texto: 'Nenhuma', valor: null },
                        { texto: 'Meios próprios', valor: '4001' },
                        { texto: 'Dutos', valor: '4002' },
                        {
                          texto: 'Linhas de transmissão',
                          valor: '4003',
                        },
                        { texto: 'Em mãos', valor: '4004' },
                        { texto: 'Por reboque', valor: '4005' },
                        {
                          texto: 'Transporte vicinal fronteiriço',
                          valor: '4006',
                        },
                      ]"
                      item-text="texto"
                      item-value="valor"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col style="padding-top: 8px; margin: 0px">
                    <v-textarea
                      v-model="varDueLocal.ObservacoesGeraisDescription"
                      dense
                      class="ml-2"
                      hide-details="auto"
                      label="Informações complementares:"
                      rows="2"
                      auto-grow
                      outlined
                    ></v-textarea>
                    <v-divider class="mt-4"></v-divider>
                  </v-col>
                </v-row>
              </template>
            </v-tab-item>
            <v-tab-item :key="2" value="notasfiscais">
              <template>
                <v-container fluid>
                  <v-row dense>
                    <v-col class="mb-3" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varDueLocal.id"
                        label="ID"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <span>Informações Básicas</span>
                  <v-divider></v-divider>
                  <puNotasLista />>
                </v-container>
              </template>
            </v-tab-item>
            <v-tab-item :key="3" value="anexacao"></v-tab-item>
          </v-tabs-items>
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// numero do rascunho - 61074506000130- treinamento
// due real para consulta 22RRV083927132

import { createNamespacedHelpers } from "vuex";
// import { validationMixin } from "vuelidate";
import DatasMixin from "@/mixins/DatasMixin";
import ValidaCPFCNPJMixin from "@/mixins/ValidaCPFCNPJMixin";
import { required } from "vuelidate/lib/validators";
import puNotasLista from "./PuDuesNFesLista";

const { mapState, mapActions } = createNamespacedHelpers("puDues");

export default {
  mixins: [DatasMixin, ValidaCPFCNPJMixin],
  validations: {
    varDueLocal: {
      CPFCNPJdoDeclarente: { required },
    },
  },

  data: () => ({
    name: "PuDuesForm",
    tituloForm: "",
    varDueLocal: {},
    corNomeRazao: "",
    showSnackBar: false,
    varRecintoAduaneiro: undefined,
    varTabs1: "tabs",
  }),
  components: { puNotasLista },

  computed: {
    ...mapState([
      "staDueSelecionado",
      "staDueDadosCNPJ",
      "listaUsuarios",
      "staMoedasSelect",
      "staUnidadeLocalRFBSelect",
      "staRecAduanDespSelect",
      "staRecAduanEmbSelect",
    ]),

    compCPFCNPJdoDeclarenteErrors() {
      const errors = [];
      if (!this.$v.varDueLocal.CPFCNPJdoDeclarente.$dirty) return errors;
      !this.$v.varDueLocal.CPFCNPJdoDeclarente.required &&
        errors.push("CPF ou CNPJ Obrigatório.");
      return errors;
    },

    compMoedasSelect() {
      return this.staMoedasSelect;
    },

    compUnidadeLocalRFBSelect() {
      return this.staUnidadeLocalRFBSelect;
    },

    compRecAduanDespSelect() {
      return this.staRecAduanDespSelect;
    },

    compRecAduanEmbSelect() {
      return this.staRecAduanEmbSelect;
    },
  },

  watch: {
    // staDueSelecionado(dueNovo) {
    //   this.metSincronizar(dueNovo);
    // },
  },

  created() {
    this.metSincronizar(this.staDueSelecionado);
    if (this.staDueSelecionado) {
      this.tituloForm = "Editar Due";
    } else {
      this.tituloForm = "Nova Due";
    }
    this.metNomeRazao(this.varDueLocal.CPFCNPJdoDeclarente);
    this.corNomeRazao = "grey lighten-1";
    this.actMoedasSelect();
    this.actUnidadeLocalRFBSelect();
    // this.actRecintoAduaneiro({ recintoAduaneiro: this.varRecintoAduaneiro });
    // this.varDueLocal.nomeRazaoDeclarante = "Obrigatório";
    // this.formataDatasDB();
  },

  mounted() {
    this.metRecAduanDespSelect(this.varDueLocal.UnidadeLocaldeDespacho);
    this.metRecAduanEmbSelect(this.varDueLocal.UnidadeLocaldeEmbarque);
  },

  methods: {
    ...mapActions([
      "actSelecionarDue",
      "actResetarDue",
      "actCriarDue",
      "actEditarDue",
      "actResetarErro",
      "actObterRazao",
      "actMoedasSelect",
      "actUnidadeLocalRFBSelect",
      "actRecAduanDespSelect",
      "actRecAduanEmbSelect",
    ]),

    metRecAduanDespSelect(unidadeRFB) {
      this.actRecAduanDespSelect(unidadeRFB);
    },
    metRecAduanEmbSelect(unidadeRFB) {
      this.actRecAduanEmbSelect(unidadeRFB);
    },

    async metNomeRazao(CPFCNPJ) {
      this.$v.varDueLocal.CPFCNPJdoDeclarente.$touch();
      if (!CPFCNPJ == "" || !CPFCNPJ == undefined) {
        var msg;
        if (CPFCNPJ.length < 14) {
          msg = "Sem formato";
          this.corNomeRazao = "grey darken-2";
        }
        if (CPFCNPJ.length == 14) {
          (await this.mixValidaCpfCnpj(CPFCNPJ))
            ? (await (msg = "CPF Válido"),
              (this.corNomeRazao = "green darken-2"))
            : (await (msg = "CPF Inválido"),
              (this.corNomeRazao = "red darken-2"));
        }
        if (CPFCNPJ.length > 14 && !CPFCNPJ.length == 18) {
          msg = "Sem formato";
          this.corNomeRazao = "grey darken-2";
        }
        if (CPFCNPJ.length == 18) {
          this.mixValidaCpfCnpj(CPFCNPJ)
            ? ((msg = await this.metObterRazao(CPFCNPJ)),
              (this.corNomeRazao = "green darken-2"))
            : ((msg = "CNPJ Inválido"), (this.corNomeRazao = "red darken-2"));
        }
        this.varDueLocal.nomeRazaoDeclarante = msg;
      } else {
        this.varDueLocal.nomeRazaoDeclarante = "";
      }
    },

    async metObterRazao(cnpj) {
      var varcnpj = cnpj
        .replace("/", "")
        .replace("-", "")
        .replace(".", "")
        .replace(".", "");
      await this.actObterRazao({ cnpj: varcnpj });
      return await this.staDueDadosCNPJ.razao_social;
    },

    selecionarDueEdicao(due) {
      this.actSelecionarDue({ due });
    },

    async salvarDue() {
      switch (this.staDueSelecionado) {
        case undefined:
          this.varDueLocal.criacao = this.$store.state.login.usuario.usuario;
          this.varDueLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          await this.actCriarDue({
            due: this.varDueLocal,
            licenca: this.$store.state.login.licenca,
          });
          this.metNomeRazao(this.varDueLocal.CPFCNPJdoDeclarente);
          if (this.$store.state.puDues.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.puDues.staErro,
            });
            this.actResetarErro();
          } else {
            this.tituloForm = "Editar Due";
            this.$root.snackbar.show({
              type: "success",
              message: "Due criada!!",
            });
          }
          break;
        default:
          this.varDueLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          this.varDueLocal.criado = undefined;
          this.varDueLocal.criacao = undefined;
          this.varDueLocal.modificado = undefined;
          await this.actEditarDue({
            due: this.varDueLocal,
            licenca: this.$store.state.login.licenca,
          });
          this.metNomeRazao(this.varDueLocal.CPFCNPJdoDeclarente);
          // console.log(this.$store.state.puDues.staErro);
          if (this.$store.state.puDues.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.puDues.staErro,
            });
            this.actResetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Registro Salvo!",
            });
          }
      }
      //this.resetar();
    },

    metMaskCpfCnpj(n) {
      if (n == null) {
        return "";
      }
      if (n.includes(".")) {
        return n.length <= 14 ? "###.###.###-##" : "##.###.###/####-##";
      } else {
        return n.length <= 11 ? "###.###.###-##" : "##.###.###/####-##";
      }
    },

    metSincronizar(due) {
      this.varDueLocal = Object.assign(
        {},
        due || {
          id: null,
          FormadeExportacaoCode: "1001",
          SituacaoEspecialdeDespachoCode: null,
          ViaEspecialdeTransporteCode: null,
        }
      );
    },
    submit() {
      // this.$v.$touch();
      this.salvarDue();
      // this.$router.back();
    },
    metClear() {
      //this.$v.$reset();
      // this.varDueLocal.ultima_compra = this.staDueSelecionado.ultima_compra;
      // this.varDueLocal.email = this.staDueSelecionado.email;
      // this.varDueLocal.due = this.staDueSelecionado.due;
      // this.varDueLocal.senha = this.staDueSelecionado.senha;
      // this.varDueLocal.ativo = this.staDueSelecionado.ativo;
      this.$root.snackbar.show({ type: "info", message: "Lista Atualizada!" });
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
